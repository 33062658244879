import React, {
  useEffect,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { useTranslation } from "react-i18next";
import { Select, Tooltip, DatePicker, Input as AntInput, Collapse } from "antd";
import styled, { css } from "styled-components";
import isEqual from "fast-deep-equal";
import { createTeleporter } from "react-teleporter";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-light-svg-icons";
import {
  faBarsFilter,
  faClose,
  faThumbtack,
} from "@fortawesome/pro-regular-svg-icons";
import { default as SelectV2 } from "components/ui/Select";

import { getEntity } from "utils/entities";
import { blue, gray, white } from "utils/constants/colors";
import { parseFilterTag } from "utils/parsers";

import {
  List,
  LoadAsYouScroll,
  SearchBar,
  Table,
  EmptyPage,
  EmptyResult,
  Pagination,
} from "modules/list/components";
import StartPage from "pages/clusters/new/components/StartPage";
import ListActions from "modules/list/actions";
import ColumnActions from "modules/list/columnActions";
import FilterActions from "modules/list/filterActions";

import { FieldWrap, Error } from "components/styled/Field";
import { MaxTagPlaceholder } from "components/ui/Fields";
import Icon from "components/ui/Icon";
import {
  faInfoCircle,
  faAngleUp,
  faAngleDown,
  faLocationDot,
  faBars,
} from "@fortawesome/pro-regular-svg-icons";
import {
  Checkbox as StyledCheckbox,
  CheckboxGroup as StyledCheckboxGroup,
} from "components/styled/Checkbox";

import { Dropdown, Tag } from "antd";
import { userSettingsCache } from "services/localstorage/cache";
import Ellipsis from "components/common/Ellipsis";
import Filters, {
  CheckboxFieldWrap,
  InputFieldWrap,
  SelectFieldWrap,
} from "components/styled/Filters";
import { cellTypes } from "./CellTypes";
import Button, { TextButton } from "components/ui/Button";
import { watchBounds } from "utils/hooks";
import {
  createListingQuickFiltersSelector,
  createListingQuickFiltersPillsSelector,
  createListingSearchFilterSelector,
  createExtendedFiltersSelector,
} from "./selectors";
import Switch from "components/common/Switch";
import i18next from "i18next";

const { Panel } = Collapse;

const TagsWrapper = styled.div`
  display: flex;
  overflow-x: auto;
  align-items: center;
`;

const FilterTag = styled(Tag)`
  border-radius: 2px;
  padding: 4px 8px;
  background: #c3e8fc;
  border: 1px solid #c3e8fc;
  cursor: pointer;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TagLabel = styled.span`
  color: #3e4856;
  font-size: 14px;
  line-height: 22px;
`;

const TagsTitle = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin-right: 4px;
`;

const SearchIcon = styled(FontAwesomeIcon)`
  width: 16px;
  height: 16px;
  color: ${gray};
  margin-right: 8px;
`;

const ViewSwitchWrapper = styled.div`
  > ${FieldWrap} {
    margin: 0;
  }
`;

// TODO: refactor this (it's from ui/Fields)
function Field(Component) {
  function FieldComponent(
    { required = true, label, validation, ...rest },
    ref
  ) {
    let error = null;
    const { t } = useTranslation();

    if (validation) {
      error = validation[0];
    }

    function renderLabel() {
      if (!label) {
        return null;
      }
      return (
        <label>
          {label} {!required && t("(Optional)")}
        </label>
      );
    }

    return (
      <FieldWrap className={error && `has-${error.status}`}>
        {renderLabel()}
        <Component ref={ref} {...rest} validateStatus={error && error.status} />
        {error && <Error>{error.result}</Error>}
      </FieldWrap>
    );
  }

  FieldComponent.displayName = `Field(${Component.displayName})`;

  return React.forwardRef(FieldComponent);
}

export const ListContext = createContext({});

export function useList() {
  return useContext(ListContext);
}

export default function createList({
  initialQuery,
  parseItems,
  schema,
  actions,
  ...rest
}) {
  const listActions = new ListActions(
    actions || { ...rest, schema, initialQuery }
  );

  const columnActions = new ColumnActions();
  const filterActions = new FilterActions();

  function ListModule({
    listState,
    children,
    initialize,
    fetchItems,
    module,
    ...rest
  }) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const hasActiveFilters = useMemo(() => {
      if (!listState) {
        return false;
      }

      const query = listState.query;
      const defaultQuery = listState.defaultQuery;

      return query && defaultQuery ? !isEqual(query, defaultQuery) : false;
    }, [listState]);

    useEffect(initialize, [module]);

    if (listState === undefined) {
      return null;
    }

    return (
      <ListContext.Provider
        value={{ module, fetchItems, ...listState, hasActiveFilters, ...rest }}
      >
        {children}
      </ListContext.Provider>
    );
  }

  const selectorsCache = {};

  const addReduxContext = connect(
    (state, ownProps) => {
      let itemsSelector = selectorsCache[ownProps.module];
      if (!itemsSelector) {
        const getListOfItems = createSelector(
          (state) => state.list[ownProps.module]?.items || {},
          (state) => state.list[ownProps.module]?.pages || new Set(),
          (state) => state.list[ownProps.module]?.currentPageNumber,
          (items, pages, currentPageNumber) => {
            if (listActions.hasPagination) {
              return items[currentPageNumber] || [];
            }
            const listOfIds = [...pages].reduce((accumulator, page) => {
              return accumulator.concat(items[page] || []);
            }, []);

            return [...new Set(listOfIds)];
          }
        );
        let getItems = getListOfItems;
        if (schema) {
          getItems = getEntity(getListOfItems, schema);
        }
        itemsSelector = getItems;
        if (parseItems) {
          itemsSelector = createSelector(getItems, parseItems);
        }

        selectorsCache[ownProps.module] = itemsSelector;
      }

      return {
        listState: state.list[ownProps.module],
        items: itemsSelector(state),
        quickFilters: createListingQuickFiltersSelector(ownProps.module)(state),
        pills: createListingQuickFiltersPillsSelector(ownProps.module)(state),
        searchFilter: createListingSearchFilterSelector(ownProps.module)(state),
        extendedFilters: createExtendedFiltersSelector(ownProps.module)(state),
      };
    },
    (dispatch, ownProps) => {
      function onColumnSort(sorter) {
        return (dispatch, getState) => {
          if (!sorter) {
            return;
          }

          const query = getState().list[ownProps.module]?.query || {};
          const { columnKey, order } = sorter;
          const sortField = order ? columnKey : "";
          const orderTypes = { ascend: "asc", descend: "desc" };
          const sortOrder = orderTypes[order] || "";

          dispatch(
            listActions.batchChangeQuery({
              query: {
                ...query,
                sortField,
                sortOrder,
              },
              module: ownProps.module,
            })
          );
        };
      }

      return {
        initialize: () => {
          if (ownProps.preventInitOnMount) {
            return;
          }
          dispatch(
            listActions.initialize(
              ownProps.module,
              initialQuery && initialQuery()
            )
          );
        },
        fetchItems: () => dispatch(listActions.fetchItems(ownProps.module)),
        nextPage: () => dispatch(listActions.nextPage(ownProps.module)),
        goToPage: (pageNumber) =>
          dispatch(
            listActions.goToPage({ module: ownProps.module, pageNumber })
          ),
        changeQuery: (name, value) => {
          if (name === "registry") {
            // TODO: this should not be here
            // it's specific to filters in profile pack selection
            userSettingsCache.set("selectedRegistry", value);
          }

          dispatch(
            listActions.changeQuery({ name, value, module: ownProps.module })
          );
          dispatch(filterActions.savePreferences({ module: ownProps.module }));
        },
        onColumnSort: (arg1, arg2, sorter) => dispatch(onColumnSort(sorter)),
        onPageSizeChange: (_, newLimit) => {
          dispatch(
            listActions.onPageSizeChange({ module: ownProps.module, newLimit })
          );
        },
        toggleFiltersVisibility: () => {
          dispatch(listActions.toggleFiltersVisibility(ownProps.module));
        },
        resetFilterQuery: () => {
          dispatch(filterActions.resetFilterQuery({ module: ownProps.module }));
          dispatch(listActions.fetchItems(ownProps.module));
        },
        columnActions: Object.keys(columnActions).reduce((acc, key) => {
          const method = columnActions[key];
          acc[key] = (payload = {}) =>
            dispatch(method({ ...payload, module: ownProps.module }));
          return acc;
        }, {}),
        filterActions: Object.keys(filterActions).reduce((acc, key) => {
          const method = filterActions[key];
          acc[key] = (payload = {}) =>
            dispatch(method({ ...payload, module: ownProps.module }));
          return acc;
        }, {}),
      };
    }
  );
  return addReduxContext(ListModule);
}

export function connectComponent(Component) {
  return function ConnectedListingComponent(props) {
    const context = useContext(ListContext);
    return <Component {...context} {...props} />;
  };
}

function connectFiltersComponent(Component) {
  return function ConnectedComponent(props) {
    const name = props.name;
    const context = useContext(ListContext);
    return (
      <Component
        value={context.query[name]}
        onChange={context.changeQuery.bind(null, name)}
        {...props}
      />
    );
  };
}

// TODO: refactor this (it's from ui/Fields)
const LabelOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .anticon.anticon-info-circle {
    margin-left: 11px;
  }
`;

// TODO move this in a different component
function SelectWithOptions({ options = [], searchBy, ...rest }) {
  function renderOption(option) {
    if (option.isGroup) {
      return (
        <Select.OptGroup label={option.label} key={option.value}>
          {option.options.map(renderOption)}
        </Select.OptGroup>
      );
    }
    return (
      <Select.Option
        key={option.value}
        value={option.value}
        {...{ [searchBy]: option[searchBy] }}
      >
        <LabelOption>
          <span title={typeof option.label === "string" && option.label}>
            {option.label}
          </span>{" "}
          {option.description && (
            <Tooltip title={option.description} placement="right">
              <Icon awesome={faInfoCircle} />
            </Tooltip>
          )}
        </LabelOption>
      </Select.Option>
    );
  }

  return (
    <Select
      maxTagPlaceholder={(values) => (
        <MaxTagPlaceholder omittedValues={values} />
      )}
      optionFilterProp={searchBy}
      {...rest}
    >
      {options.map(renderOption)}
    </Select>
  );
}

function ConnectedInput({ searchPrefix, name, ...rest }) {
  const context = useContext(ListContext);
  return (
    <AntInput
      value={context.query[name]}
      onChange={(ev) => context.changeQuery(name, ev?.target?.value)}
      prefix={searchPrefix ? <SearchIcon icon={faSearch} /> : null}
      name={name}
      {...rest}
    />
  );
}

function ConnectedCheckbox({ searchPrefix, name, ...rest }) {
  const context = useContext(ListContext);
  return (
    <StyledCheckbox
      checked={context.query[name]}
      onChange={(ev) => context.changeQuery(name, ev?.target?.checked)}
      prefix={searchPrefix ? <SearchIcon icon={faSearch} /> : null}
      name={name}
      {...rest}
    />
  );
}

export const DropdownOverlaySlot = createTeleporter();

function Filter({
  group,
  index,
  selectedTag,
  hasInnerDropdownOpen,
  onFilterTagRemove,
  onEditTag,
}) {
  const [isVisible, setIsVisible] = useState(false);

  const label = useMemo(() => {
    return group.conditions
      .map((condition) => parseFilterTag(condition))
      .join(", ");
  }, [group.conditions]);

  useEffect(() => {
    setIsVisible(selectedTag === group.guid);
  }, [selectedTag, group.guid]);

  const onVisibleChange = (visible) => {
    if (!hasInnerDropdownOpen) {
      setIsVisible(visible);
    }
  };

  return (
    <Dropdown
      key={group.guid}
      trigger={["click"]}
      overlay={isVisible ? <DropdownOverlaySlot.Target /> : <div />}
      visible={isVisible}
      onVisibleChange={onVisibleChange}
    >
      <FilterTag
        closable
        onClose={onFilterTagRemove.bind(null, index)}
        onClick={onEditTag.bind(null, group.guid)}
      >
        <TagLabel>{label}</TagLabel>
      </FilterTag>
    </Dropdown>
  );
}

function ConnectedAppliedFiltersTags({
  name,
  cache,
  cacheKey,
  onEditTag,
  selectedTag,
  hasInnerDropdownOpen,
}) {
  const { t } = useTranslation();
  const context = useContext(ListContext);
  const filterGroups = useMemo(
    () => context.query[name] || [],
    [context.query, name]
  );

  useEffect(() => {
    cache.set(cacheKey, filterGroups);
  }, [filterGroups, cache, cacheKey]);

  const onFilterTagRemove = (index) => {
    context.changeQuery(
      name,
      [...filterGroups].filter((_, groupIndex) => groupIndex !== index)
    );
  };

  const renderTag = (group, index) => {
    return (
      <Filter
        key={group.guid}
        group={group}
        index={index}
        selectedTag={selectedTag}
        onFilterTagRemove={onFilterTagRemove}
        onEditTag={onEditTag}
        hasInnerDropdownOpen={hasInnerDropdownOpen}
      />
    );
  };

  return (
    <TagsWrapper>
      <TagsTitle>{t("Filter by: ")}</TagsTitle>
      {filterGroups.length ? (
        filterGroups.map(renderTag)
      ) : (
        <span>{t("none")}</span>
      )}
    </TagsWrapper>
  );
}

function ConnectedListViewSwitch({ name }) {
  const { t } = useTranslation();
  const context = useContext(ListContext);
  const isListView = context.query[name] === "list";

  return (
    <ViewSwitchWrapper>
      <Tooltip
        title={isListView ? t("Clusters Map View") : t("Cluster List View")}
        placement="bottom"
      >
        <Button
          data-qa="list_view_switch"
          variant="flat"
          icon={<Icon awesome={isListView ? faLocationDot : faBars} />}
          onClick={() => context.changeQuery(name, isListView ? "map" : "list")}
        />
      </Tooltip>
    </ViewSwitchWrapper>
  );
}

const FilterSelectionsWrap = styled.div`
  background: #fff;
  min-height: 48px;
  border-bottom: 1px solid #dee1ea;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  padding: 8px 12px 0 12px;
  color: #6a7494;
  flex-shrink: 0;
  width: 100%;

  &:empty {
    min-height: 0;
    border-bottom: 0 none;
  }

  .ant-tag {
    background: #dcdaf9;
    border-radius: 999px;
    font-size: 12px;
    line-height: 24px;
    border: 0 none;
    margin-bottom: 8px;
    max-width: 320px;
    display: inline-flex;
    overflow: hidden;
  }

  ${Filters.FormWrap} & {
    border: 0 none;
    padding-left: 0;
    padding-right: 0;
  }
`;

const StyledClearAllButton = styled(TextButton)`
  margin-bottom: 8px;
  height: 22px;
`;

const FilterSelectionLabel = styled.span`
  margin-right: 8px;
`;

function extractOptionLabel(value, options) {
  if (!options) {
    return value;
  }

  const option = options.find((option) => option.value === value);
  return option ? option.label : value;
}

function FilterSelections({ labels = {}, options = {}, allowClearAll }) {
  const context = useContext(ListContext);
  if (!context.showFilterSelections) {
    return null;
  }

  if (
    !hasActiveFilters({
      filtersList: Object.keys(labels),
      query: context.query,
    })
  ) {
    return null;
  }

  return (
    <FilterSelectionsWrap>
      {Object.keys(context.query).map((key) => {
        if (!labels[key]) {
          return null;
        }
        let selections = context.query[key];
        if (!selections || (Array.isArray(selections) && !selections?.length)) {
          return null;
        }

        if (!Array.isArray(selections)) {
          selections = [selections];
        }

        return (
          <div key={key}>
            <FilterSelectionLabel>{labels[key]}: </FilterSelectionLabel>
            {selections.map((value) => {
              const tag = extractOptionLabel(value, options[key]);
              return (
                <Tag
                  key={value}
                  closable
                  onClose={() => {
                    const query = context.query[key];
                    let newValue;
                    if (Array.isArray(query)) {
                      newValue = query.filter((val) => val !== value);
                    }

                    if (typeof query === "string") {
                      newValue = "";
                    }

                    context.changeQuery(key, newValue);
                  }}
                >
                  <Ellipsis title={tag}>{tag}</Ellipsis>
                </Tag>
              );
            })}
          </div>
        );
      })}
      {allowClearAll ? (
        <StyledClearAllButton
          data-qa="clear-all-pills"
          onClick={context?.resetFilterQuery}
        >
          {i18next.t("Clear All")}
        </StyledClearAllButton>
      ) : null}
    </FilterSelectionsWrap>
  );
}

const onColumnHeaderResize = (column, onColumnResize) => {
  return {
    column,
    onResize: ({ width }) => {
      const { key, type } = column;
      const cellDefaults = cellTypes[type] || {};
      const { minWidth, maxWidth } = cellDefaults;
      const newWidth =
        width < minWidth ? minWidth : width > maxWidth ? maxWidth : width;
      onColumnResize({ key, newWidth });
    },
  };
};

function createColumnsOptions({
  columns = [],
  columnSettings = {},
  onColumnResize,
  resizable = false,
  tableContainerRef,
  stackingContextsRefs,
}) {
  const { hidden = [], pinned = [], resized = {} } = columnSettings;
  const newColumns = columns.slice()?.map((col) => {
    const { columnState = {}, type = "", width, key } = col;
    const cellDefaults = cellTypes[type] || {};
    const cellResized = resized[key] || "";

    const options = {
      label: col.title,
      hidden: hidden?.includes(key),
      fixed: columnState?.fixed || pinned?.includes(key),
      width: cellResized || cellDefaults?.defaultWidth || width,
      ...(columnState ? { locked: columnState?.locked } : {}),
    };

    function newRender(...args) {
      const { fixed: pinned, ...rest } = options;
      return col.render(...args, {
        ...rest,
        pinned,
        resizable,
        refs: {
          wrap: tableContainerRef,
          stackingContextsRefs,
        },
      });
    }

    return {
      ...col,
      ...options,
      ...(col.render ? { render: newRender } : {}),
      ...(resizable
        ? {
            onHeaderCell: (...args) =>
              onColumnHeaderResize(...args, onColumnResize),
          }
        : {}),
    };
  });

  return newColumns;
}

function CustomTable({ columns = [], ...rest }) {
  const tableContainerRef = React.useRef(null);
  const stackingContextsRefs = React.useRef({});
  const { resizable, columnActions = {} } = rest;
  const { getColumnsSettings = () => ({}), onColumnResize = () => {} } =
    columnActions;
  const columnSettings = getColumnsSettings();
  const newColumns = createColumnsOptions({
    columns,
    columnSettings,
    onColumnResize,
    resizable,
    tableContainerRef,
    stackingContextsRefs,
  });

  return (
    <Table
      {...rest}
      columns={newColumns}
      refs={{ stackingContextsRefs, tableContainerRef }}
    />
  );
}

const StyledSelect = styled(Select)`
  .ant-select-arrow.ant-select-arrow-loading {
    color: ${blue};
  }

  .ant-select-selection-item > span {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-select-selection-overflow {
    max-width: 45%;

    .ant-select-selection-overflow-item {
      max-width: 97%;
    }
  }

  .ant-select-selection-item .ant-select-selection-item-remove {
    min-width: 10px;
  }

  .ant-select-selection-overflow-item {
    > span {
      max-width: 100%;
    }
    .ant-tag {
      display: flex;
      align-items: center;
    }
  }
`;

const SelectTagLabel = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
`;

const Tags = ({ onChange, ...rest }) => {
  const onTagChange = (value) => {
    if (value.length === 0) {
      onChange(value);
      return;
    }

    let lastValue = value[value.length - 1].trim();
    const parsedValue = [...value];

    if (lastValue.includes(" :") || lastValue.includes(": ")) {
      lastValue = lastValue
        .split(":")
        .map((tagParts) => tagParts.trim())
        .join(":");
    }

    parsedValue.splice(value.length - 1, 1, lastValue);
    onChange(parsedValue);
  };

  const invalidTags = rest?.error?.invalidTags || [];

  return (
    <StyledSelect
      mode="tags"
      maxTagCount={1000}
      open={false}
      tagRender={({ value, label, ...others }) => {
        const isInvalidTag = invalidTags.includes(value);

        return (
          <Tag
            color={!isInvalidTag ? "default" : "error"}
            style={{ padding: "0 4px 0 8px", fontSize: 14 }}
            key={value}
            title={label}
            {...others}
          >
            <SelectTagLabel>{label}</SelectTagLabel>
          </Tag>
        );
      }}
      maxTagPlaceholder={(values) => (
        <MaxTagPlaceholder omittedValues={values} />
      )}
      tokenSeparators={[",", " "]}
      {...rest}
      onChange={onTagChange}
      data-qa-mode="tags"
    />
  );
};

const QuickFiltersWrapper = styled.div`
  min-height: 50px;
  position: sticky;
  top: -24px;
  z-index: 3;
  background-color: ${white};
  padding: 8px;
  border-bottom: 1px solid #dee1ea;
  ${FilterSelectionsWrap} {
    border-bottom: none;
  }
`;

function CheckboxFilterComponent({ filter }) {
  const { name, options } = filter;
  return (
    <Blocks.FilterFields.CheckboxGroup
      data-qa={name}
      name={name}
      options={options}
    />
  );
}

function SearchInputFilterComponent({ filter, withLabel = true }) {
  const { name, displayName } = filter;
  return (
    <SelectFieldWrap>
      <Blocks.FilterFields.Input
        data-qa={name}
        name={name}
        placeholder="Type here..."
        allowClear
        label={withLabel ? displayName : ""}
      />
    </SelectFieldWrap>
  );
}

function TagsInputFilterComponent({ filter, withLabel = true }) {
  const { name, displayName } = filter;
  return (
    <SelectFieldWrap>
      <Blocks.FilterFields.Tags
        label={withLabel ? displayName : ""}
        placeholder="Search by multiple values"
        name={name}
        data-qa={name}
        maxTagCount={1}
      />
    </SelectFieldWrap>
  );
}

function ToggleFilterComponent({ filter }) {
  const { name, displayName } = filter;
  return (
    <StyledHeader>
      <CheckboxFieldWrap>
        <Blocks.FilterFields.Checkbox data-qa={name} name={name}>
          {displayName}
        </Blocks.FilterFields.Checkbox>
      </CheckboxFieldWrap>
    </StyledHeader>
  );
}

function SelectFilterComponent({ filter, isOpened, withTrigger, allowClear }) {
  const { name, displayName, options } = filter;
  const [filteredOptions, setFilteredOptions] = useState(options);
  return (
    <Blocks.FilterFields.SelectV2
      key={name}
      name={name}
      data-qa={name}
      label={displayName}
      mode="multiple"
      options={filteredOptions}
      placeholder={"All"}
      allowClear={allowClear}
      searchEnabled
      searchPlaceholder={"Type here..."}
      searchBy="label"
      isOpened={isOpened}
      withTrigger={withTrigger}
      onSearch={(searchTerm) => {
        const filteredOptions = options.filter((opt) =>
          opt.label.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredOptions(filteredOptions);
      }}
    />
  );
}

export const QuickFiltersBounds = watchBounds();

const StyledFiltersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  > * {
    margin: 0 8px 8px 0;
  }

  ${CheckboxFieldWrap} {
    .ant-checkbox-wrapper {
      margin-bottom: 0;
    }
  }

  button {
    min-width: 50px;
  }
`;

const FilterBadge = styled.span`
  padding: 0px 4px;
  min-width: 16px;
  height: 16px;
  background-color: #7d77ca;
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
`;

const FilterCountBadge = (filter) => {
  const listingContext = useList() || {};
  const { query = {} } = listingContext;

  const noOfSelections = useMemo(() => {
    const value = query[filter.name];
    if (!value) return 0;
    return Array.isArray(value) ? value.length : 1;
  }, [filter.name, query]);

  if (noOfSelections) {
    return <FilterBadge>{noOfSelections}</FilterBadge>;
  }
};

function ExtendFiltersButton() {
  const listingContext = useList() || {};
  const {
    query = {},
    filterActions = {},
    pills: { labels = {} },
  } = listingContext;
  const noOfSelections = useMemo(() => {
    return Object.keys(labels).reduce((accum, key) => {
      const value = query[key];
      if (!value) {
        return accum;
      }

      const toAdd = Array.isArray(value) ? value.length : 1;
      return accum + toAdd;
    }, 0);
  }, [labels, query]);

  const toggleExtendedFilters = () => {
    if (filterActions.toggleExtendedFilters) {
      filterActions.toggleExtendedFilters();
    }
  };

  return (
    <Button
      data-qa="toggleExtend"
      variant="flat"
      onClick={toggleExtendedFilters}
      icon={<Icon awesome={faBarsFilter} />}
    >
      {noOfSelections ? <FilterBadge>{noOfSelections}</FilterBadge> : null}
    </Button>
  );
}

const FilterContaner = styled.div`
  display: flex;
  justify-content: space-between;
`;

function QuickFilters({ filterActionsComponent }) {
  const ref = QuickFiltersBounds.useRef();
  const listingContext = useList() || {};
  const { quickFilters, pills, searchFilter } = listingContext;
  const {
    showExtendedFilters,
    showFilterSelections,
    showPinnedFilters,
    allowClearAllPills,
  } = listingContext.filters.config;

  const renderPinnedFilters = (filtersArray = []) => {
    return (
      <>
        <Filters.Divider height="32" />
        {filtersArray.map((filter = {}) => {
          const { componentType, name } = filter;

          if (!componentType || componentType === "search") {
            return null;
          }

          return (
            <Switch value={componentType} key={name}>
              <Switch.Case value="input">
                <SearchInputFilterComponent filter={filter} />
              </Switch.Case>
              <Switch.Case value="tags">
                <TagsInputFilterComponent filter={filter} />
              </Switch.Case>
              <Switch.Case value="boolean">
                <ToggleFilterComponent filter={filter} />
              </Switch.Case>
              <Switch.Case value="dropdown">
                <SelectFilterComponent filter={filter} />
              </Switch.Case>
              <Switch.Case value="checkbox">
                <SelectFilterComponent filter={filter} />
              </Switch.Case>
            </Switch>
          );
        })}
      </>
    );
  };

  return (
    <QuickFiltersWrapper ref={ref}>
      {/* {render save filters TBD} */}
      <FilterContaner>
        <StyledFiltersWrapper>
          {searchFilter && (
            <InputFieldWrap>
              <Blocks.FilterFields.Input
                data-qa="search-filter"
                name={searchFilter.name}
                placeholder={searchFilter.placeholder || "Search..."}
                allowClear
                searchPrefix
              />
            </InputFieldWrap>
          )}
          {showPinnedFilters && renderPinnedFilters(quickFilters)}
          {showFilterSelections && (
            <Blocks.FiltersToggle visibleKeys={Object.keys(pills.labels)} />
          )}
          {showExtendedFilters && <ExtendFiltersButton />}
        </StyledFiltersWrapper>
        {filterActionsComponent}
      </FilterContaner>
      {showFilterSelections && (
        <Blocks.FilterSelections
          {...pills}
          allowClearAll={allowClearAllPills}
        />
      )}
      {/* {render total results found info TBD} */}
    </QuickFiltersWrapper>
  );
}

const StyledFiltersHeader = styled.div`
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 8px;
  height: 56px;

  .anticon {
    opacity: 0.75;
    margin-right: 8px;
    padding: 0 2px;

    &:hover {
      opacity: 1;
    }
  }
`;

const StyledFiltersContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 16px 16px;

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 12px 0 4px;
  }
  .ant-collapse-ghost
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding: 4px;
  }

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
  }

  .ant-checkbox-group-item {
    margin: 4px 0;
  }

  ${FieldWrap} {
    margin: 0;
  }

  .ant-select {
    max-width: 259px;
    .ant-select-selection-overflow {
      max-width: 40%;
      .ant-select-selection-overflow-item {
        max-width: 95%;
      }
    }
  }
`;

const PinIcon = styled(Icon)`
  font-size: 14px;
  opacity: 0;
  ${({ pinned }) => pinned && `opacity: 1;`}
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.7;
      pointer-events: none;
    `}
`;

const PinIconComponent = ({ pinned, locked, name }) => {
  const filterActions = useList().filterActions || {};
  return (
    <PinIcon
      pinned={pinned || locked}
      awesome={faThumbtack}
      disabled={locked}
      onClick={(ev) => {
        ev.stopPropagation();
        if (filterActions.togglePin) {
          filterActions.togglePin({ name });
        }
      }}
    />
  );
};

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    ${PinIcon} {
      opacity: 1;
    }
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .anticon {
    margin-left: 8px;
  }
`;

function collapseComponent(Component) {
  return function CollapseComponent(props) {
    const { displayName: label } = props.filter;
    return (
      <Collapse ghost>
        <Panel
          header={
            <StyledHeader>
              {label}
              <IconsWrapper>
                <FilterCountBadge {...props.filter} />
                <PinIconComponent {...props.filter} />
              </IconsWrapper>
            </StyledHeader>
          }
        >
          <Component {...props} />
        </Panel>
      </Collapse>
    );
  };
}

const CollapsableSearch = collapseComponent(SearchInputFilterComponent);
const CollapsableTags = collapseComponent(TagsInputFilterComponent);
const CollapsableSelect = collapseComponent(SelectFilterComponent);
const CollapsableCheckbox = collapseComponent(CheckboxFilterComponent);

const ExtendedToggleFilter = ({ filter }) => {
  return (
    <StyledHeader>
      <ToggleFilterComponent filter={filter} label={filter.label} />
      <PinIconComponent {...filter} />
    </StyledHeader>
  );
};

const renderExtendedFilter = (filter) => {
  const { componentType, name } = filter;

  return (
    <Switch value={componentType} key={name}>
      <Switch.Case value="input">
        <CollapsableSearch filter={filter} withLabel={false} />
      </Switch.Case>
      <Switch.Case value="tags">
        <CollapsableTags filter={filter} withLabel={false} />
      </Switch.Case>
      <Switch.Case value="boolean">
        <ExtendedToggleFilter filter={filter} />
      </Switch.Case>
      <Switch.Case value="dropdown">
        <CollapsableSelect
          filter={filter}
          withTrigger={false}
          allowClear={false}
        />
      </Switch.Case>
      <Switch.Case value="checkbox">
        <CollapsableCheckbox filter={filter} />
      </Switch.Case>
    </Switch>
  );
};

function ExtendedFilters() {
  const { t } = useTranslation();
  const listingContext = useList();
  const {
    extendedFilters = [],
    filterActions = {},
    filters: { pinned = [] },
  } = listingContext;
  return (
    <>
      <StyledFiltersHeader>
        <span>
          <Icon
            awesome={faClose}
            onClick={filterActions.toggleExtendedFilters}
          />
          {t("All Filters")}
        </span>
        {pinned.length ? (
          <Tooltip
            title={t("Reset pinned filters to default")}
            placement="right"
          >
            <TextButton
              data-qa="reset"
              onClick={filterActions.resetFilterPreferences}
            >
              {t("Clear pinned filters")}
            </TextButton>
          </Tooltip>
        ) : null}
      </StyledFiltersHeader>
      <StyledFiltersContent>
        {extendedFilters.map(renderExtendedFilter)}
      </StyledFiltersContent>
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 100%;
  height: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  flex-grow: 1;
  min-width: 0px;
  height: 100%;
`;

const ExtendedFiltersSection = styled.div`
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 0;
  transition: flex-basis 0.5s ease;
  overflow-y: auto;
  position: sticky;
  background-color: ${white};
  border-left: 1px solid #dee1ea;
  flex-direction: column;
  ${({ isExtended }) =>
    isExtended &&
    css`
      flex-basis: 300px;
    `}
  ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: ${maxHeight}px;
    `}

  ${({ expandedOffset }) =>
    css`
      top: ${expandedOffset}px;
    `}
`;

function FiltersLayout({
  children,
  filterActionsComponent = null,
  expandedMaxHeight,
  expandedOffset,
}) {
  const listContext = useList() || {};
  const extended = listContext.filters?.extended;
  return (
    <Wrapper>
      <ContentWrapper>
        <QuickFilters filterActionsComponent={filterActionsComponent} />
        {children}
      </ContentWrapper>
      <ExtendedFiltersSection
        isExtended={extended}
        maxHeight={expandedMaxHeight}
        expandedOffset={expandedOffset}
      >
        <ExtendedFilters />
      </ExtendedFiltersSection>
    </Wrapper>
  );
}

const FiltersToggleButton = styled(TextButton)`
  color: rgba(31, 38, 60);

  &:hover,
  &:focus {
    color: rgba(31, 38, 60);
  }
`;

const hasActiveFilters = ({ filtersList = [], query = {} }) => {
  return filtersList.some((filterName) => {
    const filterValue = query[filterName];
    if (Array.isArray(filterValue) || typeof filterValue === "string") {
      return filterValue.length;
    }

    return filterValue;
  });
};

function FiltersToggle({ visibleKeys = [] }) {
  const context = useList();
  const { query, defaultQuery } = context;

  const hasFiltersApplied = useMemo(() => {
    if (!hasActiveFilters({ filtersList: visibleKeys, query })) {
      return false;
    }

    return Object.keys(query)
      .filter((key) => !isEqual(query[key], defaultQuery?.[key]))
      .some((key) => visibleKeys.includes(key));
  }, [query, defaultQuery, visibleKeys]);

  if (!hasFiltersApplied) {
    return null;
  }

  return (
    <FiltersToggleButton onClick={() => context.toggleFiltersVisibility()}>
      <Icon awesome={context.showFilterSelections ? faAngleUp : faAngleDown} />
    </FiltersToggleButton>
  );
}

export const Blocks = {
  List: connectComponent(List),
  LoadAsYouScroll: connectComponent(LoadAsYouScroll),
  Search: connectComponent(SearchBar),
  Table: connectComponent(CustomTable),
  FiltersLayout: connectComponent(FiltersLayout),
  EmptyPage: connectComponent(EmptyPage),
  StartPage: connectComponent(StartPage),
  EmptyResult: connectComponent(EmptyResult),
  Pagination: connectComponent(Pagination),
  FilterSelections: connectFiltersComponent(FilterSelections),
  FiltersToggle: connectComponent(FiltersToggle),
  FilterFields: {
    Checkbox: Field(ConnectedCheckbox),
    Input: Field(ConnectedInput),
    SelectV2: connectFiltersComponent(SelectV2),
    CheckboxGroup: connectFiltersComponent(Field(StyledCheckboxGroup)),
    Select: connectFiltersComponent(Field(SelectWithOptions)),
    DatePicker: connectFiltersComponent(Field(DatePicker)),
    RangePicker: connectFiltersComponent(Field(DatePicker.RangePicker)),
    AppliedFiltersTags: connectFiltersComponent(ConnectedAppliedFiltersTags),
    ListViewSwitch: connectFiltersComponent(ConnectedListViewSwitch),
    Tags: connectFiltersComponent(Field(Tags)),
  },
};
